<template>
  <b-container fluid class="p-0">
    <!-- No Data Section -->
    <div v-if="(Object.keys(formAnswersList).length === 0 || Object.keys(formAnswersList.questions).length === 0) && !isLoading" class="iq-card iq-card-block iq-card-stretch iq-card-height p-3">
      <no-data></no-data>
    </div>
    <div v-else>
      <loader v-if="isLoading" :is-visible="isLoading"></loader>

      <!-- Form Section -->
      <b-row v-else>
        <b-col md="12">
          <iq-card v-for="(item,index) in formAnswersList.questions" :key="index" id="formAnswerAnalysis" class="pt-3">
            <template v-slot:headerTitle>
              <h5 class="card-title">{{ item.questionName }}</h5>
            </template>
            <div class="card-body iq-card-body">
              <div v-if="item.showPieChart">
                <b-row>
                  <b-col md="5">
                    <apex-pie-chart :data-object="getChartData(item.answerCounts)" :key="index"></apex-pie-chart>
                  </b-col>
                  <b-col md="7" style="max-height:370px !important; overflow:scroll;">
                    <b-form-group>
                      <b-form-input v-model="search"
                                    :placeholder="$t('FilterOfAnswers')"
                                    @keyup="searchAnswerList(item.answeredCustomers,index)"
                                    v-once />
                      <span>
                        <i class="la la-search"></i>
                      </span>
                    </b-form-group>
                    <b-table-simple responsive>
                      <b-thead class="thead-light">
                        <b-th>{{$t('Date')}}</b-th>
                        <b-th>{{$t('Customer')}}</b-th>
                        <b-th>{{$t('Answer')}}</b-th>
                      </b-thead>
                      <b-tbody>
                        <b-tr v-for="(answer, answerIndex) in item.answeredCustomers" :key="answerIndex">
                          <b-td>{{answer.date | dateFormat}}</b-td>
                          <b-td>
                            <router-link class="btn dropdown-item linkstyle" target="_blank"
                                         :to="{ name: 'customerSummary.details', params: { customerId: answer.customerId } }">
                              {{ answer.customerName }}
                            </router-link>
                          </b-td>
                          <b-td>{{answer.answer}}</b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </div>
              <div v-else style="max-height:370px !important; overflow:scroll;">
                <b-form-group>
                  <b-form-input v-model="search"
                                :placeholder="$t('FilterOfAnswers')"
                                @keyup="searchAnswerList(item.answeredCustomers,index)"
                                v-once />
                  <span>
                    <i class="la la-search"></i>
                  </span>
                </b-form-group>
                <b-table-simple responsive>
                  <b-thead class="thead-light">
                    <b-th>{{$t('Date')}}</b-th>
                    <b-th>{{$t('Customer')}}</b-th>
                    <b-th>{{$t('Answer')}}</b-th>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(answer, answerIndex) in item.answeredCustomers" :key="answerIndex">
                      <b-td>{{answer.date | dateFormat}}</b-td>
                      <b-td>
                        <router-link class="btn dropdown-item linkstyle" target="_blank"
                                     :to="{ name: 'customerSummary.details', params: { customerId: answer.customerId } }">
                          {{ answer.customerName }}
                        </router-link>
                      </b-td>
                      <b-td>{{answer.answer}}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
            </div>
          </iq-card>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
  import NoData from '../../../components/shared/NoData';
  import Loader from '../../../components/shared/Loader';
  import apexPieChart from '../../../components/apexCharts/ApexPieChart';

  import reportService from '../../../services/report';

  export default {
    props: {
      getPayload: String,
      chartType: String
    },
    components: {
      NoData,
      Loader,
      apexPieChart,
    },
    data() {
      return {
        formAnswersList: {},
        search: '',
        searchAnswers: [],
        isFullList: [],
        isLoading: false
      }
    },
    methods: {
      getAnswersList(url) {
        if (url && url.length > 0 && url.includes('CustomerFormTemplateID')) {
          this.isLoading = true;
          reportService.getCustomerFormAnswerAnalysis(url)
            .then(response => {
              this.formAnswersList = response;

              this.isLoading = false;
            });
        }
      },
      searchAnswerList(answers, index) {
        const oldData = answers;
        if (this.search && this.search.length > 0) {

          if (this.searchAnswers[index] && this.searchAnswers[index].length > 0)
            this.formAnswersList.questions[index].answeredCustomers = this.searchAnswers[index];

          this.formAnswersList.questions[index].answeredCustomers = this.formAnswersList.questions[index].answeredCustomers.filter(s => (s.answer && s.answer.length > 0 ? s.answer.toLowerCase().includes(this.search.toLowerCase()) : false));

          if (!this.isFullList[index]) {
            this.searchAnswers[index] = oldData;
            this.isFullList[index] = true;
          }
        }
        else {
          this.formAnswersList.questions[index].answeredCustomers = this.searchAnswers[index];
          this.isFullList[index] = false;
        }
      },
      getChartData(data) {
        let chartData = {
          chartHeader: '',
          colors: null,
          labelTranslation: false,
          labels: [],
          series: []
        }

        data.forEach((z) => {
          chartData.labels.push(z.answer);
          chartData.series.push(z.count);
        });

        return chartData;
      }
    },
    watch: {
      url: function (val) {
        this.getAnswersList(val);
      }
    },
    computed: {
      url: function () {
        return this.getPayload;
      }
    },
    mounted: function () {
      this.getAnswersList();
    }
  }
</script>

<style>
  #formAnswerAnalysis .noPaddingth {
    padding: 3px !important;
  }

  #formAnswerAnalysis .linkstyle {
    padding: 0px !important;
    margin-left: 5px !important;
    text-decoration: underline;
  }
</style>
